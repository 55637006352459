const PING_RESOURCE_URL = "/robots.txt";
const NETWORK_CHECK_POLLING_TIME = 10000;
const REQUEST_TIMEOUT = 120000;

enum LeadType {
  HighIntentLead = "HighIntentLead",
  GeneralLead = "GeneralLead",
  GBSLead = "GBSLead",
}

enum HighIntentLeadTypes {
  ORGANIC = "ORGANIC",
  WEBSITE = "WEBSITE",
  DIGITAL = "DIGITAL",
  SUBMITTED = "SUBMITTED",
  CUSTOMER_REFERRAL = "REFERRALS",
  WALKIN = "WALKIN",
  CALL_AND_EARN = "CALL_AND_EARN",
}

export const bayTypeOptions = [
  {
    label: "Bay 1",
    value: "Bay 1",
  },
  {
    label: "Bay 2",
    value: "Bay 2",
  },
  {
    label: "Bay 3",
    value: "Bay 3",
  },
];

const DownloadBatchTypes = {
  BATCH_LEADS_HOME: "BATCH_LEADS_HOME",
  FILTERED_LEADS_GLOBAL: "FILTERED_LEADS_GLOBAL",
  BATCH_LEADS_BATCH_DETAILS: "BATCH_LEADS_BATCH_DETAILS",
  FILTERED_LEADS_BATCH_DETAILS: "FILTERED_LEADS_FILTER_DETAILS",
};

const HighIntentLeadTitles = {
  [HighIntentLeadTypes.ORGANIC]: "Organic App Leads",
  [HighIntentLeadTypes.WEBSITE]: "Website Leads",
  [HighIntentLeadTypes.DIGITAL]: "Digital Leads",
  [HighIntentLeadTypes.SUBMITTED]: "Submitted Leads",
  [HighIntentLeadTypes.CUSTOMER_REFERRAL]: "Customer Referrals",
  [HighIntentLeadTypes.WALKIN]: "Walk-in Leads",
  [HighIntentLeadTypes.CALL_AND_EARN]: "Call & Earn",
};

const SendTo = {
  QUEUE: "queue",
  TCN: "tcn",
};

export {
  PING_RESOURCE_URL,
  NETWORK_CHECK_POLLING_TIME,
  REQUEST_TIMEOUT,
  LeadType,
  HighIntentLeadTypes,
  HighIntentLeadTitles,
  DownloadBatchTypes,
  SendTo,
};
